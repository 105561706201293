import { useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import './App.css'

import Token from './Pages/Token'
import SignIn from './Pages/signin'


const Main = () => {
  const [route, setRoute] = useState('');
  const [bodyData, setBodyData] = useState('');


  const callTest = async () => {
   const options = {};

    const res = await fetch(`/api/${route}`, options);
    // test version
    //const res = await fetch (`http://127.0.0.1:7071/api/${route}`, options);
    const data = await res.json();
    console.log("What is the data? ", data);
    if (data) {
          if (data.redirecturl) {
              window.location.href = data.redirecturl;
          }

      }
  }

    const callPostTest = async () => {



        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json", 'Accept': 'application/json'},
            body: `${bodyData}`
        };

        console.log(`options == ${options.body}`)
        // prod version below
        //const res = await fetch(`/api/${route}`, options);
        // test version below
        console.log("route === ", route);
        const res = await fetch (`https://time.lacontedesign.com/api/${route}`, options);
        const data = await res.json();
        console.log("What is the data? ", data);
        if (data) {
            if (data.redirecturl) {
                window.location.href = data.redirecturl;
            }

        }
    }


    const loginFast = async () => {
      const data = '/signin';
      window.location.href = data;
    }

  return (
    <div className="App">
      TimeApp
      <input onChange={e => setRoute(e.target.value)} />
      <button onClick={callTest}>Call test</button>
      <button onClick={loginFast}>Go to Login page</button>
        <div>POST TEST - route and then body</div>
        <input onChange={e => setRoute(e.target.value)}/>
        <input onChange={e => setBodyData(e.target.value)} />
      <button onClick={callPostTest}>Call POST test</button>
    </div>
  )
}

const Test = () => {
  return (
    <>
      This is a test
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/token' element={<Token />} />
        <Route path='/signin' element={<SignIn />} />
        <Route path='/test' element={<Test />} />
        <Route path='*' element={<Main />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App