import React from 'react';


const loginGoogle = async () => {
    const res = await fetch(`https://time.lacontedesign.com/api/public/signin/google`);
    const data = await res.json();
    console.log("What is the data? ", data);
    if (data) window.location.href = data.redirecturl;
}

const loginMicrosoft = async () => {
    const res = await fetch(`https://time.lacontedesign.com/api/public/signin/microsoft`);
    const data = await res.json();
    console.log("What is the data? ", data);
    if (data) window.location.href = data.redirecturl;
}

const callTest = async () => {
    const res = await fetch(`https://time.lacontedesign.com/api/public/user`);
    const data = await res.json();
    console.log("What is the data? ", data);
    //if (data) window.location.href = data.redirecturl;
}



const  signIn = () => {

    return (
        <div className="yassqueeen">
            Login
        <button onClick={loginGoogle}>Login Google</button>
        <button onClick={loginMicrosoft}>Login Microsoft</button>
            <button onClick={callTest}>call public</button>
        </div>
    )
}

export default signIn;


